import PropTypes from 'prop-types';
import { MoneyType } from './MoneyType';
import { CompanyType } from './CustomersType';
import dayjs from '../config/dayjs';

const ConfirmingOperationType = PropTypes.shape({
  id: PropTypes.string,
  contactEmail: PropTypes.string,
  expirationDate: PropTypes.instanceOf(dayjs),
  bankAccount: PropTypes.shape({
    id: PropTypes.string,
    bankName: PropTypes.string,
    accountNumber: PropTypes.string,
  }),
  confirmingSet: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      company: CompanyType,
      folio: PropTypes.string,
      dateIssued: PropTypes.string,
      dateExpiration: PropTypes.string,
      amountWithIva: MoneyType,
    }),
  ),
});

const ConfirmingPayrollType = PropTypes.shape({
  id: PropTypes.string,
  createdAt: PropTypes.instanceOf(dayjs),
  operations: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: ConfirmingOperationType,
      }),
    ),
  }),
});

export { ConfirmingPayrollType, ConfirmingOperationType };
