/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

const InvoiceType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  isRatificated: PropTypes.bool,
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rut: PropTypes.string,
    hasInvoiceProviderCredentials: PropTypes.bool,
    pendingReintegrations: PropTypes.shape({
      count: PropTypes.number,
    }),
    masterEntity: PropTypes.shape({
      id: PropTypes.string.isRequired,
      rut: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }),
  receiver: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rut: PropTypes.string,
    name: PropTypes.string.isRequired,
    company: PropTypes.shape({
      id: PropTypes.string.isRequired,
      rut: PropTypes.string.isRequired,
      hasInvoiceProviderCredentials: PropTypes.bool,
    }),
  }),
  collectionManager: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
});

export { InvoiceType };
